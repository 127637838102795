import React from 'react';
// import { graphql } from 'gatsby';
import Layout from '../components/layout';
// import { HelmetDatoCms } from 'gatsby-source-datocms';
import Paragraph from '../components/paragraph';
import Container from '../components/container';
import Header3 from '../components/header3';


const PrivatePolicyPage = ({ data }) => {

    const companyFullName = "Kwiaty i więcej...";

    return (
        <Layout>
            {/* <HelmetDatoCms seo={data.datoCmsPolicyPage.seoMetaTags}></HelmetDatoCms> */}
            <Container className='text-gray-800'>
                <div className='max-w-xl md:mx-auto sm:text-center lg:max-w-2xl'>
                    <Header3>Polityka Prywatności</Header3>
                </div>
                <div className="space-y-8">
                    <Paragraph>
                        Klauzula informacyjna o przetwarzaniu danychNa podstawie art. 13 ust. 1 i ust. 2 rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z 27.4.2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (dalej: RODO), informuję, że:
                    </Paragraph>
                    <Paragraph>
                        <strong className={"block"}>Administrator danych:</strong>
                        Administratorem danych osobowych jest {companyFullName}
                    </Paragraph>
                    <Paragraph>
                        <strong className={"block"}>Cel zbierania danych osobowych</strong>
                        Przetwarzanie Państwa danych osobowych odbywa się na podstawie art. 6 RODO i w celach marketingowych , Administrator Danych Osobowych powołuje się na prawnie uzasadniony interes, którym jest analityka internetowa strony www, analiza odwiedzin itp.
                    </Paragraph>
                    <Paragraph>
                        <strong className={"block"}>Okres przechowywania danych osobowych:</strong>
                        Państwa dane osobowe będą przechowywane przez okres od nawiązania współpracy przez okres 5 lat po jej rozwiązaniu.
                    </Paragraph>
                    <Paragraph>
                        <strong className={"block"}>Prawo dostępu do danych osobowych:</strong>
                        Posiadają Państwo prawo dostępu do treści swoich danych osobowych prawo do ich sprostowania.
                    </Paragraph>
                    <Paragraph>
                        <strong className={"block"}>Prawo do usunięcia danych:</strong>
                        Możecie Państwo w każdej chwili usunąć jakiekolwiek dane przetwarzane przez {companyFullName}, z wyjątkiem następujących sytuacji:
                        <br />
                        - otwarte zamówienie/umowa, które nie zostały jeszcze zakończone lub tylko częściowo.
                        <br />
                        - nieuregulowany dług wobec {companyFullName}, niezależnie od metody płatności.
                        <br />
                        - Państwa dług został sprzedany firmie zewnętrznej w ciągu ostatnich trzech lat lub jednego roku (w przypadku klientów, którzy zmarli)
                    </Paragraph>
                    <Paragraph>
                        <strong className={"block"}>Prawo wniesienia skargi do organu nadzorczego:</strong>
                        Przysługuje Państwu prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych, gdy uznają Państwo, iż przetwarzanie Państwa danych osobowych dotyczących narusza przepisy RODO.
                        Podanie przez Państwa danych osobowych jest wymogiem warunkiem zawarcia umowy.
                    </Paragraph>
                    <Paragraph>
                        <strong className={"block"}>Podstawy prawne przetwarzania danych</strong>
                        W przypadku przetwarzania uzyskanych od Państwa danych osobowych każdorazowo poinformujemy Cię, czy zostały one przekazane w sposób regulaminowy, czy jest wymagane podpisanie umowy oraz czy masz obowiązek przekazać swoje dane osobowe i jakie są możliwe konsekwencje niewyrażenia na to zgody.
                    </Paragraph>
                    <Paragraph>
                        <strong className={"block"}>Zautomatyzowane podejmowanie decyzji, profilowanie :</strong>
                        Państwa dane osobowe nie będą przetwarzane w sposób zautomatyzowany i nie będą profilowane.
                    </Paragraph>
                </div>
            </Container>
        </Layout>
    )
};

export default PrivatePolicyPage;

// export const query = graphql`
//     query PrivatePolicyPageQuery {
//         datoCmsPolicyPage {
//             seoMetaTags {
//               ...GatsbyDatoCmsSeoMetaTags
//             }
//         }
//     }
// `
